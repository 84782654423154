import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import styles from './DatastoreScalingNodeInfoCard.module.less';
import CcxIcon1NodeDeployment from '../../ccx/icons/CcxIcon1NodeDeployment';
import TypographyText from '../../../tmp/TypographyText';
import Space from '../../common/antd/Space';
import { CcxIconCpu, CcxIconDisk, CcxIconRam } from '../../ccx/icons/Icons';
import AppForm from '../../ccx/common/AppForm';
import { useEffect, useState, MouseEvent } from 'react';
import DeploymentsItem from '../../../types/DeploymentsItem';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { Services } from '../../../services/ServiceService';
import DbService from '../../../types/DbService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import DeploymentOptions from '../../../types/DeploymentOptions';
import { InstanceSizeClassProps } from '../../../types/InstanceSize';
import LazyLoader from '../../LazyLoader';

interface DatastoreScalingNodeInfoCardProps {
    roleName: string;
    instanceType: string;
    instanceName: string;
    cpu: number;
    ram: number;
    volumeSize: number;
    numberOfNodesToRemove: number;
    countryCode?: string;
    availabilityZone: string;
    showCheckbox: boolean;
    onCheckChange?: (value: boolean) => void;
    checkedCount: number;
    setCheckedCount: (value: number) => void;
    dataStore: DeploymentsItem;
    testId?: string;
}

function DatastoreScalingNodeInfoCard({
    roleName,
    instanceType,
    instanceName,
    cpu,
    ram,
    volumeSize,
    countryCode,
    availabilityZone,
    showCheckbox,
    numberOfNodesToRemove,
    onCheckChange,
    checkedCount,
    setCheckedCount,
    dataStore,
    testId = 'DatastoreScalingNodeInfoCard',
}: DatastoreScalingNodeInfoCardProps) {
    const isPrimary = roleName === 'primary' || roleName === 'master';

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const isGalera = dataStore?.getClusterType() === 'Galera';

    const canRemoveNode = (isPrimary && isGalera) || !isPrimary;

    const onChange = (e: CheckboxChangeEvent) => {
        if (!canRemoveNode) {
            return;
        }

        const isChecked = e.target.checked;

        const newCheckedCount = isChecked ? checkedCount + 1 : checkedCount - 1;

        setCheckedCount(newCheckedCount);

        setIsChecked(isChecked);

        onCheckChange && onCheckChange(isChecked);
    };

    const onClick = (e: MouseEvent<HTMLDivElement>) => {
        if (!showCheckbox || isPrimary) {
            return;
        }

        setIsChecked(!isChecked);

        onCheckChange && onCheckChange(!isChecked);
    };

    useEffect(() => {
        setIsChecked(false);
    }, [numberOfNodesToRemove]);

    return (
        <Col
            data-testid={testId}
            sm={12}
            xs={24}
            className={styles.DatastoreScalingNodeInfoCard}
            onClick={onClick}
        >
            <span className={styles.DatastoreScalingNodeInfoCardRole}>
                {isPrimary ? 'primary' : 'replica'}
            </span>

            <div className={styles.DatastoreScalingNodeInfoCardCol}>
                <main>
                    <Row>
                        <Col span={24}>
                            <div className={styles.DatastoreScalingCheckboxRow}>
                                <div
                                    className={styles.DatastoreScalingCheckbox}
                                >
                                    {showCheckbox && (
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={onChange}
                                            disabled={
                                                !canRemoveNode ||
                                                (checkedCount ===
                                                    numberOfNodesToRemove &&
                                                    !isChecked)
                                            }
                                        />
                                    )}
                                    <CcxIcon1NodeDeployment
                                        height={20}
                                        width={20}
                                    />
                                    <TypographyText strong={true}>
                                        {instanceName}
                                    </TypographyText>
                                </div>
                                <Row>
                                    <Col>
                                        {!canRemoveNode && (
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        Primary node cannot be
                                                        removed.
                                                    </span>
                                                }
                                            />
                                        )}

                                        {canRemoveNode && (
                                            <>
                                                {isChecked && (
                                                    <TypographyText
                                                        strong={true}
                                                    >
                                                        <span
                                                            className={
                                                                styles.DatastoreScalingNodeRemove
                                                            }
                                                        >
                                                            (will be removed)
                                                        </span>
                                                    </TypographyText>
                                                )}

                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            All data on this
                                                            node will be lost.
                                                            This action is
                                                            irreversible.
                                                        </span>
                                                    }
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </div>

                            <div className={styles.DatastoreScalingInstance}>
                                <TypographyText muted={true}>
                                    Instance: {instanceType}
                                </TypographyText>
                                <br />
                                <Space className={styles.DatastoreScalingSpace}>
                                    {cpu ? (
                                        <Space size={4}>
                                            <Tooltip title="CPU">
                                                <CcxIconCpu
                                                    className={
                                                        styles.DatastoreScalingSpecIcon
                                                    }
                                                />
                                            </Tooltip>
                                            {cpu}
                                        </Space>
                                    ) : null}

                                    {ram ? (
                                        <Space size={4}>
                                            <Tooltip title="RAM">
                                                <CcxIconRam
                                                    className={
                                                        styles.DatastoreScalingSpecIcon
                                                    }
                                                />
                                            </Tooltip>
                                            {ram} GB
                                        </Space>
                                    ) : null}

                                    <Space size={4}>
                                        <Tooltip title="Disk">
                                            <CcxIconDisk
                                                className={
                                                    styles.DatastoreScalingSpecIcon
                                                }
                                            />
                                        </Tooltip>
                                        {volumeSize} GB
                                    </Space>

                                    {countryCode && (
                                        <Space size={4}>
                                            <Tooltip title="Region">
                                                <AppFlagIcon
                                                    code={countryCode}
                                                />
                                            </Tooltip>
                                            {availabilityZone}
                                        </Space>
                                    )}
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </main>
            </div>
        </Col>
    );
}

interface AddCardData {
    instance_size: string;
    availability_zone: string;
    index: number;
}

interface Option {
    label: string;
    value: string;
}

interface DatastoreScalingNodeAddCardProps {
    kind: string;
    azs: Option[];
    instanceSizes: Option[];
    index: number;
    onChange?: (data: AddCardData) => void;
}

function DatastoreScalingNodeAddCard({
    kind,
    azs,
    instanceSizes,
    index,
    onChange,
}: DatastoreScalingNodeAddCardProps) {
    const [form] = Form.useForm();
    const [errorFields, setErrorFields] = useState([]);
    const [az, setAz] = useState<string>('');
    const [instanceSize, setInstanceSize] = useState<string>('');

    const fields = [
        {
            required: true,
            label: 'Availability Zone',
            placeholder: 'Availability Zone',
            type: 'select',
            name: 'availability_zone',
            onChange: setAz,
            options: azs,
        },
        {
            required: true,
            label: 'Instance Size',
            placeholder: 'Instance Size',
            type: 'select',
            name: 'instance_size',
            onChange: setInstanceSize,
            options: instanceSizes,
        },
    ];

    useEffect(() => {
        onChange &&
            onChange({
                instance_size: instanceSize,
                availability_zone: az,
                index,
            });
    }, [az, instanceSize]);

    azs.length === 1 && form.setFieldValue('availability_zone', azs[0].value);

    return (
        <Col sm={12} xs={24} className={styles.DatastoreScalingNodeInfoCard}>
            <span className={styles.DatastoreScalingNodeInfoCardRoleSecondary}>
                {kind} (new)
            </span>
            <div className={styles.DatastoreScalingNodeInfoCardCol}>
                <main>
                    <Row>
                        <Col span={24}>
                            <div className={styles.DatastoreScalingNodeIcon}>
                                <CcxIcon1NodeDeployment
                                    height={20}
                                    width={20}
                                />
                                <TypographyText strong={true}>
                                    New node
                                </TypographyText>
                            </div>
                            <div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    scrollToFirstError={true}
                                >
                                    <AppForm
                                        fields={fields}
                                        errorFields={errorFields}
                                    />
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </main>
            </div>
        </Col>
    );
}

export type ScalingType = 'up' | 'down' | 'none';

export interface DatastoreScalingNodeInfoCardsValues {
    node_uuids: string[];
    add_nodes: {
        instance_size: string;
        availability_zone: string;
    }[];
    scaling_type: ScalingType;
}

interface Props {
    dataStore: DeploymentsItem;
    services: Services;
    numNodes: number;
    deploymentOptions: DeploymentOptions;
    onUpdate?: (data: DatastoreScalingNodeInfoCardsValues) => void;
    testId?: string;
}

export default function DatastoreScalingNodeInfoCards({
    services,
    dataStore,
    numNodes,
    deploymentOptions,
    onUpdate,
    testId = 'DatastoreScalingNodeInfoCards',
}: Props) {
    const [nodesForRemoval, setNodesForRemoval] = useState<string[]>([]);
    const [checkedCount, setCheckedCount] = useState(0);
    const [newNodes, setNewNodes] = useState<AddCardData[]>([]);
    const [needNodes, setNeedNodes] = useState<number>(0);
    const [existingNodes, setExistingNodes] = useState<any[]>(
        services.dbServices
    );

    useEffect(() => {
        setExistingNodes(services.dbServices);
    }, [services]);

    const numberOfNodesToRemove =
        dataStore.numberOfNodes - numNodes
            ? dataStore.numberOfNodes - numNodes
            : 0;

    const showCheckbox = needNodes < 0;

    useEffect(() => {
        const tempNeededNodes = numNodes - dataStore.numberOfNodes;
        setNeedNodes(tempNeededNodes);
        if (tempNeededNodes > needNodes) {
            addNewNode(tempNeededNodes);
        } else if (needNodes > tempNeededNodes || tempNeededNodes === 0) {
            removeNodes(tempNeededNodes);
        }
    }, [numNodes]);

    const azs: CloudProviderRegion[] =
        deploymentOptions &&
        dataStore &&
        deploymentOptions.network.availability_zones[
            dataStore.getCloudProvider().code
        ]
            ? deploymentOptions.network.availability_zones[
                  dataStore.getCloudProvider().code
              ][dataStore.cloudRegion.code]
            : [];

    const availabilityZoneOpts = azs.map((region: CloudProviderRegion) => {
        const label = region.name;
        const value = region.code;
        return { label, value };
    });

    const instanceSizes: InstanceSizeClassProps[] =
        deploymentOptions &&
        deploymentOptions.instance.instanceSizes[
            dataStore.getCloudProvider().code
        ]
            ? deploymentOptions.instance.instanceSizes[
                  dataStore.getCloudProvider().code
              ]
            : [];

    const instanceOpts = instanceSizes.map(
        (instance: InstanceSizeClassProps) => {
            const label = `${instance.name}: ${instance.cpu} Cores, ${instance.ram} GB`;
            const value = instance.type;

            return { label, value };
        }
    );

    const instanceDetails = instanceSizes.reduce((p, c) => {
        const { type, cpu, ram } = c;
        p.set(type, { cpu, ram });
        return p;
    }, new Map<string, { cpu: number; ram: number }>());

    const addNewNode = (nodesToAdd: number) => {
        let tempNodes: AddCardData[] = [];
        const newNodesNumber = newNodes?.length ? newNodes?.length : 0;
        const newNodesNeeded = nodesToAdd - newNodesNumber;
        tempNodes = new Array(newNodesNeeded)
            .fill(newNodesNumber)
            .map((_, i) => {
                return {
                    availability_zone: '',
                    instance_size: '',
                    index: newNodes.length + i,
                };
            });
        setNewNodes([...newNodes, ...tempNodes]);
    };

    const removeNodes = (nodesToRemove: number) => {
        const tempNewNodes: AddCardData[] = newNodes.slice(0, nodesToRemove);
        setNewNodes(tempNewNodes);
    };

    const emitChanges = () => {
        if (!onUpdate) {
            return;
        }

        const addNodes = newNodes.map((node: AddCardData) => {
            const { instance_size, availability_zone } = node;
            return { instance_size, availability_zone };
        });

        let scalingType: ScalingType = 'none';

        if (needNodes > 0) {
            scalingType = 'up';
        } else if (needNodes < 0) {
            scalingType = 'down';
        }

        onUpdate({
            node_uuids: nodesForRemoval,
            add_nodes: addNodes,
            scaling_type: scalingType,
        });
    };

    useEffect(() => {
        emitChanges();
    }, [nodesForRemoval, numNodes]);

    useEffect(() => {
        setNodesForRemoval([]);
        setCheckedCount(0);
    }, [numNodes]);

    const onCheckChange = (uuid: string, value: boolean) => {
        if (value) {
            setNodesForRemoval([...nodesForRemoval, uuid]);
        } else {
            const updatedUuids = nodesForRemoval?.filter(
                (item: any) => item !== uuid
            );
            setNodesForRemoval(updatedUuids);
        }
        emitChanges();
    };

    const onChange = (data: AddCardData) => {
        const nodes = [...newNodes];
        const nodeIndex = nodes.findIndex((n) => n.index === data.index);
        if (nodeIndex < 0) {
            return;
        }
        nodes[nodeIndex].availability_zone = data.availability_zone;
        nodes[nodeIndex].instance_size = data.instance_size;
        setNewNodes([...nodes]);
        emitChanges();
    };

    const infoCard = (db: DbService) => {
        const instance = instanceDetails.get(db.getInstanceType());
        const cpu = instance ? instance.cpu : 0;
        const ram = instance ? instance.ram : 0;
        const countryCode = db.getRegion()?.countryCode ?? '';
        const volumeSize = db.getDiskSize();

        return (
            <DatastoreScalingNodeInfoCard
                key={db.getServiceUuid()}
                roleName={db.getRoleName()}
                instanceType={db.getInstanceType()}
                instanceName={db.getHostnameOrIP()}
                cpu={cpu}
                ram={ram}
                checkedCount={checkedCount}
                setCheckedCount={setCheckedCount}
                numberOfNodesToRemove={numberOfNodesToRemove}
                volumeSize={volumeSize}
                countryCode={countryCode}
                availabilityZone={db.getAvailabilityZone()}
                showCheckbox={showCheckbox}
                onCheckChange={(value: boolean) =>
                    onCheckChange(db.getServiceUuid(), value)
                }
                dataStore={dataStore}
            />
        );
    };

    const newKind = dataStore.getNewNodesKind();

    const addCard = (data: AddCardData) => {
        return (
            <DatastoreScalingNodeAddCard
                kind={newKind}
                key={data.index}
                azs={availabilityZoneOpts}
                instanceSizes={instanceOpts}
                index={data.index}
                onChange={onChange}
            />
        );
    };

    if (!deploymentOptions || !dataStore) {
        return <LazyLoader type="row" rows={1} />;
    }
    return (
        <>
            <div className={styles.AppExistingNodesCard}>
                {existingNodes.map(infoCard)}
            </div>
            <div className={styles.AppNewNodesCard}>
                {newNodes.map(addCard)}
            </div>
        </>
    );
}
