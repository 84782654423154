import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './DeploymentDashboard.module.less';
import useDataStore from '../../../core/hooks/useDataStore';
import { Switch, Select, Row, Col, Divider } from 'antd';
import AppRangePicker from '../../ccx/common/AppRangePicker';
import DeploymentDashboardOptions from './DeploymentDashboardOptions';
import HostDashboard from './HostDashboard';
import DbDashboard from './DbDashboard';
import AppLabeledComponent from '../../ccx/common/AppLabeledComponent';
import CloudInstance from '../../../types/CloudInstance';
import LbDashboard from './LbDashboard';
import dayjs from 'dayjs';
import CcxComponentProps from '../../../core/CcxComponent';
import DeploymentsItem from '../../../types/DeploymentsItem';
import { ChartsContext } from '../../../core/context/ChartsContext';
import { usePrivateIPs } from '../../../core/CcxEnv';

interface UrlProps {
    dataStoreUuid: string;
    activeTab: string;
    projectUuid: string;
    tab: string;
}

interface Props extends CcxComponentProps {
    currentDeployment: DeploymentsItem;
}

function DeploymentDashboard({
    currentDeployment,
    testId = 'DeploymentDashboard',
}: Props): ReactElement {
    const [host, setHost] = useState<CloudInstance | undefined>(undefined);
    const [hostUUID, setHostUUID] = useState('');
    const [port, setPort] = useState<number>(0);
    const { dataStoreUuid, activeTab, projectUuid, tab } =
        useParams<UrlProps>();
    const { dataStore: deployment } = useDataStore(dataStoreUuid);
    const [rangeFrom, setRangeFrom] = useState<string | undefined>(undefined);
    const [rangeTo, setRangeTo] = useState<string | undefined>(undefined);
    const [range, setRange] = useState('30m');
    const [refreshInterval, setRefreshInterval] = useState(30000);
    const [currentTab, setCurrentTab] = useState('host');
    const [currentHost, setCurrentHost] = useState('');
    const [currentNode, setCurrentNode] = useState(undefined);
    const [displaySummary, setDisplaySummary] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [availableNodes, setAvailableNodes] = useState<any>({
        hosts: [],
        dbNodes: [],
        lbNodes: [],
    });
    const [field, setField] = useState<any>(undefined);

    const history = useHistory();

    const chartsContext = useContext(ChartsContext);

    const fieldSetup = {
        placeholder: 'Select A Host',
        disabled: false,
        options: [],
    };

    const { Option } = Select;

    useEffect(() => {
        chartsContext.setChartRange(undefined);
    }, []);

    useEffect(() => {
        if (deployment) {
            if (tab === 'lb') {
                selectLbNode();
            } else if (tab === 'db') {
                selectDbNode();
            } else {
                selectInstanceHost();
            }

            interface Node {
                privateIp: string;
                publicIp: string;
                port: number;
                getFqdnPortWithRole(): string;
            }

            const createOption = (n: Node) => {
                const option = {
                    value: `${n.publicIp}:${n.port}`,
                    label: n.getFqdnPortWithRole(),
                };

                if (usePrivateIPs) {
                    option.value = `${n.privateIp}:${n.port}`;
                }

                return option;
            };

            // load available lb nodes
            const availableLbNodes = deployment.getLbNodes().map(createOption);
            // load available db nodes
            const availableDbNodes = deployment.getDbNodes().map(createOption);

            // load available unique hosts
            const filteredLbHosts = availableLbNodes.map((n: any) => {
                const [host, , role] = n.label.split(/[:/]/);
                return {
                    value: n.value,
                    label: `${host}/${role}`,
                };
            });

            const filteredDbHosts = availableDbNodes.map((n: any) => {
                const [host, , role] = n.label.split(/[:/]/);
                return {
                    value: n.value,
                    label: `${host}/${role}`,
                };
            });

            const availableHosts = filteredDbHosts;

            filteredLbHosts.map((item: any) => {
                let hFound = false;
                availableHosts.forEach((i: any) => {
                    if (i.label === item.label) {
                        hFound = true;
                    }
                });
                if (!hFound) {
                    availableHosts.push(item);
                }
            });

            setAvailableNodes({
                hosts: availableHosts,
                lbNodes: availableLbNodes,
                dbNodes: availableDbNodes,
            });
        }
    }, [deployment, tab]);

    useEffect(() => {
        if (availableNodes) {
            const newField = fieldSetup;
            switch (currentTab) {
                case 'db':
                    newField.options = availableNodes.dbNodes;
                    setField(newField);
                    break;
                case 'lb':
                    newField.options = availableNodes.lbNodes;
                    setField(newField);
                    break;
                case 'host':
                    newField.options = availableNodes.hosts;
                    setField(newField);
                    break;
                default:
                    newField.options = availableNodes.hosts;
                    setField(newField);
            }
        }
    }, [availableNodes, currentTab]);

    const handleNodeChange = (value: any) => {
        setCurrentNode(value.split(':')[0]);

        switch (currentTab) {
            case 'db':
                selectDbNode(value);
                break;
            case 'lb':
                selectLbNode(value);
                break;
            case 'host':
                selectInstanceHost(value);
                break;
        }
    };

    const selectInstanceHost = (selected?: string) => {
        if (deployment) {
            let found: CloudInstance[] = selected
                ? deployment.getDbNodes().filter((val: CloudInstance) => {
                      return isSelectedHost(selected, val);
                  })
                : deployment.getDbNodes();

            if (!found || found.length === 0) {
                found = deployment.getLbNodes();
            }
            if (found.length > 0) {
                setHost(found[0]);
            } else {
                setHost(undefined);
            }
        }
    };

    const selectDbNode = (selected?: string) => {
        if (deployment) {
            const found: CloudInstance[] = selected
                ? deployment.getDbNodes().filter((val: CloudInstance) => {
                      return isSelectedHost(selected, val);
                  })
                : deployment.getDbNodes();

            if (!found || found.length === 0) {
                setHost(undefined);
            }
            if (found.length > 0) {
                setHost(found[0]);
            }
        }
    };

    const selectLbNode = (selected?: string) => {
        if (deployment) {
            const found: CloudInstance[] = selected
                ? deployment.getLbNodes().filter((val: CloudInstance) => {
                      return isSelectedHost(selected, val);
                  })
                : deployment.getLbNodes();

            if (!found || found.length === 0) {
                setHost(undefined);
            }
            if (found.length > 0) {
                setHost(found[0]);
            }
        }
    };

    const isSelectedHost = (selected: string, node: CloudInstance) => {
        if (usePrivateIPs) {
            return (
                `${node.privateIp}:${node.port}` === selected ||
                `${node.privateIp}` === selected
            );
        }

        return (
            `${node.publicIp}:${node.port}` === selected ||
            `${node.publicIp}` === selected
        );
    };

    useEffect(() => {
        if (host) {
            setHostUUID(host.hostUuid);
            setPort(host.port);
        }
    }, [host]);

    useEffect(() => {
        switch (currentTab) {
            case 'db':
                selectDbNode(currentNode);
                break;
            case 'lb':
                selectLbNode(currentNode);
                break;
            case 'host':
                selectInstanceHost(currentNode);
                break;
        }
    }, [currentTab]);

    useEffect(() => {
        if (tab) {
            setCurrentTab(tab);
        }
    }, [tab]);

    useEffect(() => {
        if (currentTab === 'lb') {
            setCurrentHost(host ? host.getFqdnPortWithRole() : 'Loading');
        } else {
            setCurrentHost(
                host ? `${host.getFqdnWithRole(true)}` : 'Loading...'
            );
        }
    }, [host, currentTab]);

    const handleTabChange = (tab: string) => {
        setCurrentTab(tab);
        history.push(
            `/projects/${projectUuid}/data-stores/${dataStoreUuid}/${activeTab}/${tab}`
        );
    };

    const handleDisplaySummaryChange = (value: boolean) => {
        setDisplaySummary(value);
    };

    const handleIntervalChange = (value: boolean) => {
        setRefreshInterval(value ? 30000 : 0);
    };

    const handleRangeChange = (from: any, to?: number) => {
        // from and to parameters are set only for custom ranges
        if (to && from) {
            setRangeTo(dayjs(to * 1000).format());
            setRangeFrom(dayjs(from * 1000).format());
            setRange('custom');
        } else {
            // must unset "to" when only "from" was passed
            setRangeTo(undefined);

            if (from) {
                // from is always passed, but in this case it's not a number but the value of the radio group
                // which is a string like '1d'
                const minutes = 1000 * 60;
                const hours = minutes * 60;
                const days = hours * 24;
                const date = new Date();

                chartsContext.setChartRange(from);

                switch (from) {
                    case '30m':
                        date.setTime(date.getTime() - 30 * minutes);
                        break;
                    case '1h':
                        date.setTime(date.getTime() - 1 * hours);
                        break;
                    case '1d':
                        date.setTime(date.getTime() - 1 * days);
                        break;
                    case '1w':
                        date.setTime(date.getTime() - 7 * days);
                        break;
                }

                setRangeFrom(date.toISOString());
                setRange(from);
            }
        }
    };

    const handleLoadingData = (value: boolean) => {
        setLoadingData(value);
    };

    return (
        <section className={styles.DeploymentDashboard} data-testid={testId}>
            <Row>
                <Col sm={14} lg={6} xs={24}>
                    <DeploymentDashboardOptions
                        value={currentTab}
                        onChange={handleTabChange}
                        onSummaryChange={handleDisplaySummaryChange}
                        disabled={loadingData}
                        deployment={currentDeployment}
                        hasLoadBalancers={
                            deployment && deployment.getLbNodes().length > 0
                        }
                    />
                </Col>

                <Col sm={10} lg={6} xs={24}>
                    <AppLabeledComponent label="Node:" softText={false}>
                        <Select
                            disabled={loadingData}
                            onChange={handleNodeChange}
                            className={styles.DeploymentDashboardHostSelect}
                            value={currentHost}
                        >
                            {field?.options.map((o: any) => {
                                return (
                                    <Option value={o.value} key={o.value}>
                                        {o.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AppLabeledComponent>
                </Col>
                <Col sm={14} lg={9} xs={24}>
                    <AppRangePicker
                        onChange={handleRangeChange}
                        value={range}
                        disabled={loadingData}
                    />
                </Col>
                <Col sm={10} lg={3} xs={24}>
                    <AppLabeledComponent
                        isInline={true}
                        label="Refresh (30s):"
                        softText={false}
                    >
                        <Switch
                            defaultChecked
                            onChange={handleIntervalChange}
                            disabled={loadingData}
                        />
                    </AppLabeledComponent>
                </Col>
            </Row>

            <Divider />

            {currentTab === 'host' && (
                <HostDashboard
                    uuid={dataStoreUuid}
                    host_uuid={hostUUID}
                    port={port}
                    from={rangeFrom}
                    to={rangeTo}
                    interval={refreshInterval}
                    hidden={currentTab !== 'host'}
                    displaySummary={displaySummary}
                    onLoading={handleLoadingData}
                />
            )}

            {currentTab === 'db' && (
                <DbDashboard
                    uuid={dataStoreUuid}
                    host_uuid={hostUUID}
                    port={port}
                    from={rangeFrom}
                    to={rangeTo}
                    interval={refreshInterval}
                    node={host}
                    hidden={currentTab !== 'db'}
                    displaySummary={displaySummary}
                    onLoading={handleLoadingData}
                />
            )}

            {currentTab === 'lb' && (
                <LbDashboard
                    uuid={dataStoreUuid}
                    host_uuid={hostUUID}
                    port={port}
                    from={rangeFrom}
                    to={rangeTo}
                    interval={refreshInterval}
                    node={host}
                    hidden={currentTab !== 'lb'}
                    displaySummary={displaySummary}
                    onLoading={handleLoadingData}
                />
            )}
        </section>
    );
}

export default DeploymentDashboard;
