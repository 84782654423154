import {
    AUTH_APP_URL,
    AUTH_REDIRECT_URL,
    AUTH_UI_APP_DISABLED,
} from '../core/CcxEnv';
import { getProjectOwner } from '../core/helpers';

export type RequestProps = {
    data?: object;
    redirect?: boolean;
    version?: number;
};

export default class ApiService {
    // protected static async request(
    //   path: string,
    //   method: string,
    //   props?: RequestProps
    // ): Promise<any> {}

    protected static getApiUrl(version?: number): string | void {}

    /**
     * Send request to backend
     */
    protected static async request(
        method: string,
        path: string,
        props?: RequestProps
    ): Promise<any> {
        let responseData;
        const headers: any = getProjectOwner();
        const response = await fetch(
            `${this.getApiUrl(props?.version)}/${path}`,
            {
                method,
                credentials: 'include',
                body: props?.data ? JSON.stringify(props?.data) : null,
                headers,
            }
        );

        if (response.status === 401) {
            try {
                responseData = await response.json();
                if (!responseData.admin) {
                    if (AUTH_REDIRECT_URL) {
                        window.location.href = AUTH_REDIRECT_URL;
                    } else if (AUTH_UI_APP_DISABLED) {
                        window.location.href = AUTH_APP_URL;
                    } else {
                        window.location.href = `${AUTH_APP_URL}/?redirectTo=${encodeURIComponent(
                            window.location.href
                        )}&from=ccx`;
                    }
                }
            } catch (err) {
                responseData = null;
            }
        }

        try {
            responseData = await response.json();
        } catch (err) {
            responseData = null;
        }

        if (!response.ok) {
            throw new Error(responseData?.err || response.statusText);
        }

        return responseData;
    }
}
