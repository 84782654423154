import { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, message, Row } from 'antd';
import { useParams } from 'react-router';
import CcxComponentProps from '../../../../core/CcxComponent';
import ProvisionService from '../../../../services/ProvisionService';
import Deployments from '../../../../types/Deployments';
import styles from './DataStoreSettings.module.less';
import DataStoreSettingsMaintenance from './DataStoreSettingsMaintenance';
import DataStoreSettingsNameChange from './DataStoreSettingsNameChange';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import useBackupSettings from '../../../../core/hooks/useBackupSettings';
import BackupService from '../../../../services/BackupService';
import { useAppDispatch } from '../../../../redux/hooks';
import { getAllDataStores } from '../../../../slices/datastores.slice';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import { getNonErroredJobs } from '../../../../core/helpers';
import LazyLoader from '../../../LazyLoader';
import DatastoreSettingsAuthenticationTable from './DatastoreSettingsAuthenticationTable';
import DatastoreEmailNotification from './DatastoreEmailNotification';

interface UrlProps {
    dataStoreUuid: string;
}

interface Props extends CcxComponentProps {
    dataStores?: Deployments;
    currentDeployment?: DeploymentsItem | null | undefined;
}

function DataStoreSettingsGeneral({
    dataStores,
    currentDeployment,
    testId = 'DataStoreSettingsGeneral',
}: Props): ReactElement {
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const { dataStoreUuid } = useParams<UrlProps>();
    const [dataStoreName, setDataStoreName] = useState<string | undefined>(
        undefined
    );
    const [maintenanceDayOfWeek, setMaintenanceDayOfWeek] = useState<
        number | undefined
    >(undefined);
    const [maintenanceStartHour, setMaintenanceStartHour] = useState<
        number | undefined
    >(undefined);

    const [newDataStoreName, setNewDataStoreName] = useState<
        string | undefined
    >(undefined);
    const [newDayOfWeek, setNewDayOfWeek] = useState<number | undefined>(
        undefined
    );
    const [newStartHour, setNewStartHour] = useState<number | undefined>(
        undefined
    );
    const [newEndHour, setNewEndHour] = useState<number | undefined>(undefined);
    const [newPassword, setNewPassword] = useState<string | undefined>(
        undefined
    );
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<
        string | undefined
    >(undefined);
    const [saving, setSaving] = useState(false);
    const [dataStore, setDataStore] = useState<DeploymentsItem | null>(null);
    const [canSave, setCanSave] = useState(false);
    const [canSaveBackup, setCanSaveBackup] = useState(false);
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [emailsList, setEmailsList] = useState<string[]>([]);
    const [full, setFull] = useState<boolean | undefined>();
    const [incremental, setIncremental] = useState<boolean | undefined>();
    const [frequency, setFrequency] = useState<number | undefined>();
    const [time, setTime] = useState<number | undefined>();

    const [fullCron, setFullCron] = useState<string>('');
    const [incCron, setIncCron] = useState<string>('');
    const {
        jobs,
        refresh: refreshJobs,
        loading: jobsLoading,
    } = useDataStoreJobs({
        dataStoreUuid,
    });

    const { backupSettings, refresh: refreshBackupSettings } =
        useBackupSettings(dataStoreUuid);

    useEffect(() => {
        if (backupSettings) {
            setFullCron(backupSettings.fullCron || '');
            setIncCron(backupSettings.incCron || '');
            setIncremental((backupSettings.incFrequency || 0) > 0);

            setFull((backupSettings.fullFrequency || 0) > 0);

            if (backupSettings.fullFrequency === 1) {
                setFrequency(backupSettings.fullFrequency);
            } else if (backupSettings.fullFrequency === 2) {
                setFrequency(backupSettings.fullFrequency);
                setTime(backupSettings.fullStartHour);
            }
        }
    }, [backupSettings]);

    useEffect(() => {
        if (dataStores) {
            setDataStore(dataStores?.getByUuid(dataStoreUuid));
        }
    }, [dataStores]);

    useEffect(() => {
        if (dataStore) {
            setDataStoreName(dataStore.getName());
            setMaintenanceDayOfWeek(dataStore.getMaintenanceDayOfWeek());
            setMaintenanceStartHour(dataStore.getMaintenanceStartHour());
            form.setFieldsValue({
                maintenanceStartHour: dataStore.getMaintenanceStartHour(),
            });
            form.setFieldsValue({
                maintenanceDayOfWeek: dataStore.getMaintenanceDayOfWeek(),
            });
            form.setFieldsValue({
                notificationRecipients: dataStore.getNotificationsEmailList(),
            });
            setEmailsList(dataStore.getNotificationsEmailList());
        }
    }, [dataStore]);

    const saveDataStoreName = (name: string) => {
        if (name !== dataStoreName) setNewDataStoreName(name);
        setCanSave(true);
    };

    const saveDayOfWeek = (dayOfWeek: number) => {
        if (dayOfWeek) setNewDayOfWeek(dayOfWeek);
        setCanSave(true);
    };

    const saveTimeWindow = (startHour: number, endHour: number) => {
        if (startHour >= 0) {
            setNewStartHour(startHour);
            setNewEndHour(endHour);
        }
        setCanSave(true);
    };

    const saveEmailNotifications = (emails: string[]) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emails.every((email: string) => {
            return emailRegex.test(email);
        });
        setEmailsList(emails);
        if (!isValidEmail) {
            setShowEmailError(true);
            setCanSave(false);
        } else {
            setShowEmailError(false);
            setCanSave(true);
        }
    };

    const handleSaveClick = async () => {
        try {
            if (newDataStoreName) await form.validateFields(['dataStoreName']);
            if (newDayOfWeek)
                await form.validateFields(['maintenanceDayOfWeek']);
            if (newStartHour || newEndHour)
                await form.validateFields(['maintenanceStartHour']);
            if (newPassword || newPasswordConfirm)
                await form.validateFields(['password', 'confirmPassword']);
        } catch (e) {
            message.error(
                'One or more settings are invalid. Please double check and try again.'
            );
            return;
        }

        setSaving(true);
        if (canSave) {
            try {
                await ProvisionService.updateDeployment({
                    dataStoreUuid,
                    dataStoreName: newDataStoreName?.trim(),
                    dayOfWeek:
                        newDayOfWeek ||
                        form.getFieldValue('maintenanceDayOfWeek'),
                    startHour:
                        newStartHour ||
                        form.getFieldValue('maintenanceStartHour'),
                    endHour:
                        newEndHour ||
                        (form.getFieldValue('maintenanceStartHour') + 2) % 24,
                    proxySqlAdminPassword:
                        newPassword === newPasswordConfirm
                            ? newPassword
                            : undefined,
                    notifications: {
                        enabled: !!emailsList.length,
                        emails: emailsList,
                    },
                });
                dispatch(getAllDataStores());
                message.success('Your datastore settings have been updated.');
                setCanSave(false);
                setSaving(false);
                setNewDataStoreName(undefined);
                setNewDayOfWeek(undefined);
                setNewStartHour(undefined);
                setNewEndHour(undefined);
                setNewPassword(undefined);
                setNewPasswordConfirm(undefined);
            } catch (e) {
                message.error(
                    'There was an error updating your datastore settings. Please try again.'
                );
                setSaving(false);
                return;
            }
        }

        if (canSaveBackup) {
            try {
                const settingsObj = {
                    full_frequency: full ? frequency : 0,
                    full_start_hour: frequency === 2 ? time : undefined,
                    inc_frequency: incremental ? 1 : 0,
                };

                await BackupService.updateBackupSettings(
                    dataStoreUuid,
                    settingsObj
                );
                refreshBackupSettings && (await refreshBackupSettings());
                message.success('Your backup settings have been updated.');
                setCanSaveBackup(false);
                setSaving(false);
            } catch (e) {
                message.error(
                    'There was an error updating your backup settings. Please try again.'
                );
                setSaving(false);
                return;
            }
        }
    };

    const handleUpgradeTo = async () => {
        if (dataStore?.can_upgrade_to) {
            try {
                await ProvisionService.upgradeDatastore({
                    dataStoreUuid,
                    canUpgradeTo: dataStore?.can_upgrade_to,
                });

                refreshJobs();

                message.info('Your datastore is being upgraded.');
            } catch (error) {
                message.error(
                    'There was an error upgrading your datastore. Please try again.'
                );
                return;
            }
        }
    };

    return jobsLoading && !dataStore ? (
        <LazyLoader type="row" />
    ) : (
        <div className={styles.DataStoreSettings} data-testid={testId}>
            <DataStoreSettingsNameChange
                dataStoreName={dataStoreName}
                form={form}
                onChange={saveDataStoreName}
            />
            <Divider />
            <DataStoreSettingsMaintenance
                currentDeployment={currentDeployment}
                form={form}
                maintenanceDayOfWeek={maintenanceDayOfWeek}
                maintenanceStartHour={maintenanceStartHour}
                onDayOfWeekChange={saveDayOfWeek}
                onTimeWindowChange={saveTimeWindow}
                canUpgradeTo={dataStore?.can_upgrade_to}
                handleUpgradeTo={handleUpgradeTo}
                filteredJob={getNonErroredJobs(jobs)}
            />

            <Divider />
            <DatastoreEmailNotification
                form={form}
                emailsList={emailsList}
                saveEmailNotifications={saveEmailNotifications}
            />

            <Divider />
            <DatastoreSettingsAuthenticationTable />

            <Divider />

            <Row>
                <Col
                    className={styles.DataStoreSettingsButtonContainer}
                    span={24}
                >
                    <Button
                        data-testid={`${testId}FormNextButton`}
                        loading={saving}
                        onClick={handleSaveClick}
                        type="primary"
                        htmlType="button"
                        disabled={!(canSave || canSaveBackup)}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default DataStoreSettingsGeneral;
