import { ReactElement, useCallback, useEffect } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './ProjectsDropdown.module.less';
import CcxComponentProps from '../../core/CcxComponent';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLocalStorage, setLocalStorage } from '../../core/helpers';
import { getAllDataStores } from '../../slices/datastores.slice';
import { setProjects } from '../../slices/projects.slice';

interface UrlProps {
    projectUuid?: string;
}

interface Props extends CcxComponentProps {}

function ProjectsDropdown({
    testId = 'ProjectsDropdown',
}: Props): ReactElement {
    const { user } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const projectUuid = window.location.pathname.split('/')[2];
    const history = useHistory();
    const currentProject = useAppSelector((state) => state.projects);

    const onChangeProject = useCallback(
        async (project: string = 'default') => {
            const isDefault = user?.scopes.find(
                (scope: any) => scope.owner === project
            );
            const newProjectOwner = isDefault || user?.scopes[0];
            history.push(`/projects/${project}/data-stores`);
            if (newProjectOwner) {
                dispatch(setProjects(newProjectOwner));
                await setLocalStorage('project', newProjectOwner);
                dispatch(getAllDataStores());
            }
        },
        [user?.scopes, dispatch, history]
    );

    useEffect(() => {
        const project = getLocalStorage('project');
        if (project) {
            onChangeProject(project.owner);
        } else {
            onChangeProject('default');
        }
    }, [onChangeProject, user?.scopes]);

    const scopes =
        user?.scopes?.filter((scope: any) => scope.owner !== user?.id) || [];

    if (scopes.length === 0) return <></>;

    const menu = () => {
        return (
            <Menu
                activeKey={projectUuid}
                onClick={(e) => onChangeProject(e.key)}
            >
                <Menu.Item
                    data-testid={`${testId}AddDataStoreMenuOption`}
                    key={`default`}
                >
                    <NavLink
                        data-testid={`${testId}AddDataStoreLink`}
                        to={`/projects/default/data-stores`}
                    >
                        Default
                    </NavLink>
                </Menu.Item>
                {scopes.length > 0 &&
                    scopes.map((scope: any) => (
                        <Menu.Item
                            data-testid={`${testId}AddDataStoreMenuOption`}
                            key={scope.owner}
                        >
                            <NavLink
                                data-testid={`${testId}AddDataStoreLink`}
                                to={`/projects/${scope.owner}/data-stores`}
                            >
                                {scope.owner}
                            </NavLink>
                        </Menu.Item>
                    ))}
            </Menu>
        );
    };

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                data-testid={`${testId}Button`}
                className={styles.ProjectDropdownButton}
            >
                {projectUuid === user?.id ? 'Default' : projectUuid}
            </Button>
        </Dropdown>
    );
}

export default ProjectsDropdown;
