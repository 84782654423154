import React, { ReactElement } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import styles from './AppCreateButton.module.less';
import CcxIconDownOutlined from '../ccx/icons/CcxIconDownOutlined';
import CreateVpcButton from '../vpc/CreateVpcButton';
import CcxComponentProps from '../../core/CcxComponent';
import { vpcDisabled } from '../../core/CcxEnv';
import User from '../../types/User';
import { useAppSelector } from '../../redux/hooks';
import Deployments from '../../types/Deployments';

interface UrlProps {
    projectUuid?: string;
}

interface Props extends CcxComponentProps {}

function AppCreateButton({ testId = 'AppCreateButton' }: Props): ReactElement {
    const { datastores } = useAppSelector((state) => state.datastores);

    const { user } = useAppSelector((state) => state.user);

    const projectUuid = window.location.pathname.split('/')[2];

    const menu = () => {
        const limitReached = userReachedDatastoreLimit(datastores, user);
        return (
            <Menu>
                <Menu.Item
                    data-testid={`${testId}AddDataStoreMenuOption`}
                    key={`/projects/${
                        projectUuid || 'default'
                    }/data-stores/add`}
                    disabled={limitReached}
                    title={limitReached ? user?.disabledText : ''}
                >
                    <NavLink
                        data-testid={`${testId}AddDataStoreLink`}
                        to={`/projects/${
                            projectUuid || 'default'
                        }/data-stores/add`}
                    >
                        Datastore
                    </NavLink>
                </Menu.Item>

                {!vpcDisabled && (
                    <Menu.Item
                        data-testid={`${testId}AddVpcMenuOption`}
                        key={`/projects/${projectUuid || 'default'}/vpcs/add`}
                        title={limitReached ? user?.disabledText : ''}
                    >
                        <CreateVpcButton
                            buttonType="text"
                            buttonText="VPC"
                            className={styles.AppCreateButtonCreateVpcLink}
                            testId={`${testId}AddVpcButton`}
                        />
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                data-testid={`${testId}Button`}
                className={styles.AppCreateButtonButton}
            >
                Create <CcxIconDownOutlined />
            </Button>
        </Dropdown>
    );
}

export function userReachedDatastoreLimit(
    datastores: Deployments | undefined,
    user?: User
) {
    return (
        user?.verifiedLevel === 0 &&
        (user?.maxDataStores || 0) > 0 &&
        (datastores?.notDeletingLength() || 0) >= (user?.maxDataStores || 0)
    );
}

export default AppCreateButton;
