import { AxiosResponseHeaders } from 'axios';
import {
    AUTH_APP_URL,
    AUTH_REDIRECT_URL,
    AUTH_UI_APP_DISABLED,
} from '../core/CcxEnv';
import { getProjectOwner } from '../core/helpers';

const headers: any = getProjectOwner();
        
export const SDK_AXIOS_DEFAULT_BASE_OPTIONS = {
    // to prevent triggering errors on error status and look into response.ok props instead
    validateStatus: () => true,
    headers,
    transformResponse: [
        (data: any, headers: AxiosResponseHeaders, status: number) => {
            // this os copy&paste from src/services/ApiService.tsx method ApiService.request
            if (status === 401) {
                try {
                    const response = JSON.parse(data[0]);
                    if (!response.admin) {
                        if (AUTH_REDIRECT_URL) {
                            window.location.href = AUTH_REDIRECT_URL;
                        } else if (AUTH_UI_APP_DISABLED) {
                            window.location.href = AUTH_APP_URL;
                        } else {
                            window.location.href = `${AUTH_APP_URL}/?redirectTo=${encodeURIComponent(
                                window.location.href
                            )}&from=ccx`;
                        }
                    }
                } catch (err) {
                    return null;
                }
            }

            let response: any;
            try {
                response = JSON.parse(data);
            } catch (err) {
                return null;
            }

            const error = response?.err || response?.statusText;
            if (error) {
                throw new Error(error);
            }
            return response;
        },
    ],
};
