import { createSlice } from '@reduxjs/toolkit';

interface ProjectsInterface {
    owner: string;
    role: string;
}

const initialState = {
    owner: '',
    role: '',
} as ProjectsInterface;

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (state, action) => {
            state.owner = action.payload.owner;
            state.role = action.payload.role;
        },
    },
});
export const { setProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
